import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import HeaderMenu from "./components/menu";
// import ProfileDashboard from './components/Profile'
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
 
root.render(
  // <ChakraProvider>
    <React.StrictMode>
 
      <App />
     
    </React.StrictMode>
  // {/* </ChakraProvider> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
