import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
// import { SplitButton } from "primereact/splitbutton";
import { Avatar } from "primereact/avatar";
// import NewMenu from "../components/NewMenu";
import UserActions from "../components/Sample";
import SideBar from "../pages/sidebar";
import YourProgress from "./trainings/yourProgress";
import BasicDemo from "../components/primemenu";
import logo from '../static/images/logo-1.png'
export default function TabMenuComponent() {
  const tab1HeaderTemplate = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <i className="pi pi-fw pi-calendar" />
        {options.titleElement}
      </button>
    );
  };

  const testComponent = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <i className="pi pi-fw pi-chart-line" />
        {options.titleElement}
      </button>
    );
  };

  const tab2HeaderTemplate = (options) => {
    return (
      <div
        className="flex align-items-center px-3"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <Avatar
          image={logo}
          shape="circle"
          className="mx-2"
        />
      </div>
    );
  };

  //   const tab3HeaderTemplate = (options) => {
  //     const items = [
  //       { label: "Update", icon: "pi pi-refresh" },
  //       { label: "Delete", icon: "pi pi-times" },
  //       { label: "Upload", icon: "pi pi-upload" },
  //     ];

  //     return (
  //       <SplitButton
  //         label="Header III"
  //         icon="pi pi-plus"
  //         onClick={options.onClick}
  //         className="px-2"
  //         model={items}
  //       ></SplitButton>
  //     );
  //   };

  return (
    <div>
    
      <div className="card">
        <TabView>
          <TabPanel
            headerTemplate={tab2HeaderTemplate}
            headerClassName="flex align-items-center"
          ></TabPanel>
          <TabPanel header="Training" headerTemplate={tab1HeaderTemplate}>
            {/* <SideBar /> */}
            {/* <NewSample /> */}
            {/* <div style={{ right: "16222px" }}/> */}

            {/* <UserActions /> */}
            <BasicDemo />
          </TabPanel>

          <TabPanel header="Test" headerTemplate={testComponent}>
            <p className="m-0">Hi tim</p>
          </TabPanel>

          <TabPanel header="Your progress" headerTemplate={testComponent}>
            <YourProgress />
          </TabPanel>

          {/* <TabPanel
          headerTemplate={tab3HeaderTemplate}
          headerClassName="flex align-items-center"
        >
          <p className="m-0">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
            est et expedita distinctio. Nam libero tempore, cum soluta nobis est
            eligendi optio cumque nihil impedit quo minus.
          </p>
        </TabPanel> */}
        </TabView>
      </div>
    </div>
  );
}
