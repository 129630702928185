import React from "react";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Accordion, AccordionTab } from "primereact/accordion";
export default function BasicDemo() {
  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} ml-2 text-primary`;
    const style = { fontSize: "1.25rem" };

    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName} style={style}>
          Header
        </span>
      </div>
    );
  };

  return (
    
    <div>
      
      <center>
        <div className="Home">
          <h1>Modules</h1>
        </div>
      </center>
      <br />
      <br />

      <div>
        <Accordion activeIndex={0}>
          <AccordionTab header="About us">
            <p className="m-0">About us section</p>
          </AccordionTab>
          <AccordionTab header="Door Hardware">
            <p className="m-0">Info on Door Hardware....</p>
            <br />
            <Accordion activeIndex={0}>
              {/* starting */}
              <AccordionTab header="Finishes">
                <p className="m-0">About Finishes</p>
              </AccordionTab>
              <AccordionTab header="Types o Locks">
                <p className="m-0">About Types of Locks</p>
              </AccordionTab>

              <AccordionTab header="Lock Funtions">
                <p className="m-0">
                 About Lock Funtions ...
                </p>
              </AccordionTab>
              <AccordionTab header="Keyways">
                <p className="m-0">
                 About Keyways ...
                </p>
              </AccordionTab>
              <AccordionTab header="Types of Lock cylinders">
                <p className="m-0">
                 About Lock cylinders ...
                </p>
              </AccordionTab>

              <AccordionTab header="Backsst">
                <p className="m-0">
                 About Lock Backsst ...
                </p>
              </AccordionTab>

              <AccordionTab header="Handing">
                <p className="m-0">
                 About Handing...
                </p>
              </AccordionTab>

              <AccordionTab header="Door Closing">
                <p className="m-0">
                 About Door Closing...
                </p>
              </AccordionTab>
            </Accordion>

            {/* ending */}
          </AccordionTab>

          <AccordionTab header="Next topics .....">
            <p className="m-0">
              Topics goes here,
            </p>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}
