import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css"; 
import TabMenuComponent from "./components/TabMenu"; 
// import "primeflex/primeflex.css"; 
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TabMenuComponent />} />
        {/* <Route path="/new" element={<MyComponent />} />
        <Route path="/menu" element={<BasicDemo />} /> */}
        {/* <Route path="/component" element={<Overview />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
