import React from "react";
// import "./TimelineDemo.css";
// import { Timeline } from 'primereact/timeline';

export default function userProgress() {
  return (
    <div className="content-wrapper">
      <div className="row flex-grow">
        {/* do css for changing card styles for the below */}
        <div className="card card-rounded">
          <div className="card-body">
            <div className="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 className="card-title card-title-dash">Training status</h4>
                <p className="card-subtitle card-subtitle-dash">
                  You have 50+ topics to complete
                </p>
              </div>
            </div>
            <div className="table-responsive mt-1">
              <table className="table select-table">
                <thead>
                  <tr>
                    <th>Topics</th>
                    <th>Progress</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>Locks</h6>
                      <p>On LocksandSafes</p>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                          <p className="text-success">79%</p>
                          <p>85/162</p>
                        </div>
                        <div className="progress progress-md">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={10}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-opacity-warning">
                        In progress
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Locks</h6>
                      <p>On LocksandSafes</p>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                          <p className="text-success">65%</p>
                          <p>85/162</p>
                        </div>
                        <div className="progress progress-md">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={10}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-opacity-warning">
                        In progress
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Locks</h6>
                      <p>On LocksandSafes</p>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                          <p className="text-success">65%</p>
                          <p>85/162</p>
                        </div>
                        <div className="progress progress-md">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={10}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-opacity-warning">
                        In progress
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Locks</h6>
                      <p>On LocksandSafes</p>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                          <p className="text-success">65%</p>
                          <p>85/162</p>
                        </div>
                        <div className="progress progress-md">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={10}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-opacity-danger">Pending</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Locks</h6>
                      <p>On LocksandSafes</p>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
                          <p className="text-success">65%</p>
                          <p>85/162</p>
                        </div>
                        <div className="progress progress-md">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={70}
                            aria-valuemin={0}
                            aria-valuemax={10}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-opacity-success">
                        Completed
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
